import DataJson from '../../data.json';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';

export function getRandomKeyWithDatePrefix() {
  let randstr = '';
  let datestr = '';
  let monthdir = '';
  try {
    randstr = ('000000' + Math.floor(Math.random() * 1000000)).slice(-6);
    datestr = new Date().toLocaleString('sv').split(' ')[0];
    monthdir = datestr.slice(0, 7);
  } catch (ex) {}
  return `${monthdir || 'unknown'}/${datestr}_${randstr}`;
}

export async function uploadFile(file, key, options = {}) {
  const {acl = 'public-read', unique_key = true} = options;
  const resp = await ApiUtil.req(
    `${DataJson.storageHost}/storage/presigned/url?client_id=${DataJson.clientId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        'Content-Type': file.type,
        key,
        acl,
        unique_key,
      },
    },
  );

  const {expected, fields, url} = resp;
  const formData = new FormData();

  for (const key in fields) {
    formData.set(key, fields[key]);
  }
  formData.set('file', file);

  const uploadResp = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  if (uploadResp.status.toString()[0] !== '2') {
    throw uploadResp;
  }

  return expected;
}
