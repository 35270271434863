import React from 'react';
import styled from 'styled-components';
import Theme from '../../Theme';
import {NoteEdit} from '@styled-icons/fluentui-system-regular/NoteEdit';
import {Person} from '@styled-icons/bootstrap/Person';
import {Chat} from '@styled-icons/fluentui-system-regular/Chat';
import {CheckmarkCircle} from '@styled-icons/fluentui-system-regular/CheckmarkCircle';

export default function RegisterProgress(props) {
  const {current = 1} = props;
  return (
    <TopWrapper style={{paddingBottom: 20, paddingLeft: 0}}>
      <ul>
        {[
          {
            step: '01',
            label: '資料填寫',
            icon: NoteEdit,
          },
          {},
          {
            step: '02',
            label: '資料審核',
            icon: Person,
          },
          {},
          {
            step: '03',
            label: '理監事審議',
            icon: Chat,
          },
          {},
          {
            step: '04',
            label: '入會成功',
            icon: CheckmarkCircle,
          },
        ].map((it, idx) => {
          if (!it.step) {
            return <li className="placeholder" key={idx} />;
          }
          const step = parseInt(it.step, 10);
          const color = current >= step ? Theme.colors.main : '#aaa';
          return (
            <li key={idx}>
              <div
                style={{
                  color,
                  paddingBottom: 10,
                  fontWeight: 'bold',
                  fontSize: 14,
                }}>
                {it.step}
              </div>
              <figure>
                <it.icon size={32} color={color} />
              </figure>
              <div style={{color, fontSize: 14}}>{it.label}</div>
            </li>
          );
        })}
      </ul>
    </TopWrapper>
  );
}

const TopWrapper = styled.section`
  max-width: 640px;
  margin: 0 auto;

  & > ul {
    display: flex;
    align-items: center;
    list-item-style: none;
    padding: 0;
    margin: 0;
    font-size: 16px;

    & > li {
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0 0 80px;
      &.placeholder {
        flex: 1;
        height: 2px;
        background-color: #aaa;
      }
    }
  }
`;
