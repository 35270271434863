import React from 'react';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import Theme from '../../Theme';
import * as Ant from 'antd';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as AppActions from '../../AppActions';
import DataJson from '../../../data.json';
import * as UploadUtil from '../../Utils/UploadUtil';
import {validatePendingRegisterForm} from '../../Utils/ValidationUtil';
import RjsfAddressField from '../../Components/RjsfAddressField';
import RegisterProgress from '../../Components/RegisterProgress';

const ExpertiseMap = {
  mechanical: {
    type: 'boolean',
    title: '機械',
  },
  operational: {
    type: 'boolean',
    title: '作業運輸',
  },
  biotechnology: {
    type: 'boolean',
    title: '生技醫藥',
  },
  chemical: {
    type: 'boolean',
    title: '化工',
  },
  electronic: {
    type: 'boolean',
    title: '電子及電力',
  },
  semiconductor: {
    type: 'boolean',
    title: '半導體製程及元件',
  },
  information: {
    type: 'boolean',
    title: '資通訊',
  },
  photoelectric: {
    type: 'boolean',
    title: '光電物理',
  },
  industrial_design: {
    type: 'boolean',
    title: '工業設計',
  },
  supplies: {
    type: 'boolean',
    title: '民生用品',
  },
};

const ExpertiseDefaults = Object.keys(ExpertiseMap).reduce((acc, k) => {
  acc[k] = false;
  return acc;
}, {});

const ExpertiseList = Object.keys(ExpertiseMap).map((k) => ({
  field: k,
  label: ExpertiseMap[k].title,
  defaultValue: false,
}));

function MemberRegister(props) {
  const [photo, setPhoto] = React.useState(null);
  const [photoObjUrl, setPhotoObjUrl] = React.useState(null);
  const [chPhoto, setChPhoto] = React.useState(null);
  const [chPhotoObjUrl, setChPhotoObjUrl] = React.useState(null);
  const [cert, setCert] = React.useState(null);
  const [certObjUrl, setCertObjUrl] = React.useState(null);
  const [extraInfo, setExtraInfo] = React.useState({
    name: '',
    name_eng: '',
    email: '',
    email_2: '',
    birthday: '',
    phone: '',
    gender: true,
    id_card_number: '',
    contact_address: {
      city: '',
      district: '',
      zip: '',
      detail: '',
    },
    certificate_number: '',
    national_examination_year: '',
    agency_name: '',
    agency_name_eng: '',
    agency_address: {
      city: '',
      district: '',
      zip: '',
      detail: '',
    },
    agency_address_eng: '',
    agency_telephone_prefix: '+886',
    agency_telephone: '',
    agency_telephone_ext: '',
    agency_fax: '',
    agency_business_id_number: '',
    public: true,
    accept_agreement: false,
    expertise: {
      ...ExpertiseDefaults,
      etc: false,
      etc_text: '',
    },
    secretary_name: '',
    secretary_phone: '',
    secretary_email: '',
    trademark_attorney: '',
    chpatent: false,
    chpatent_detail: '',
  });

  function extraInfoProps(keyname) {
    return {
      value: extraInfo[keyname],
      onChange: (e) => {
        setExtraInfo({
          ...extraInfo,
          [keyname]: e.target.value,
        });
      },
    };
  }

  async function submitRegistrationForm() {
    if (disabled) {
      return;
    }

    AppActions.setLoading(true);
    try {
      const photoUrl = await UploadUtil.uploadFile(
        photo,
        'photo/' + photo.name,
      );

      const certS3Key = `private_uploads/${UploadUtil.getRandomKeyWithDatePrefix()}`;
      await UploadUtil.uploadFile(cert, certS3Key, {
        acl: 'private',
        unique_key: false,
      });

      await ApiUtil.req(`${DataJson.apiHost}/twpaa/pending_registration`, {
        method: 'POST',
        data: {
          ...extraInfo,
          photo: photoUrl,
          cert: certS3Key,
        },
      });
      window.alert(
        `成功提出申請！請至您的信箱：${extraInfo.email} 收取信件並確認申請進度`,
      );
      AppActions.navigate('/');
    } catch (ex) {
      console.warn('REV_DBG', ex);
      Ant.message.warn(`API Error: ${JSON.stringify(ex)}`);
      if (ex?.response?.error === 'id_card_number_exists') {
        window.alert('此使用者已經註冊過了!');
      }
    }
    AppActions.setLoading(false);
  }

  const validationErrorMap = validatePendingRegisterForm({
    ...extraInfo,
    photo,
    cert,
  });

  const validationErrorList = Object.keys(validationErrorMap).map(
    (k) => validationErrorMap[k],
  );

  const disabled = validationErrorList.length > 0;

  return (
    <Wrapper>
      <div style={{height: 30}} />
      <RegisterProgress current={1} />

      <h2>入會申請書</h2>
      <section style={{paddingTop: 20}}>
        <p>致專利師公會：</p>
        <p>
          本人茲申請加入社團法人中華民國專利師公會為會員，凡會中一切決議及有關規章均願誠謹遵守。
          檢附專利師證書掃描檔、個人照片電子檔。
        </p>
        <div style={{marginTop: 15}}>
          <Ant.Checkbox
            checked={extraInfo.accept_agreement}
            onChange={(e) => {
              setExtraInfo({
                ...extraInfo,
                accept_agreement: e.target.checked,
              });
            }}>
            同意
          </Ant.Checkbox>
        </div>
      </section>

      <h2 style={{marginTop: 40}}>基本資料</h2>
      <section>
        <div className="field file">
          <label style={{alignSelf: 'flex-start'}} className="required">
            照片
          </label>
          <div className="image-picker">
            <label htmlFor="photo_input">
              {photoObjUrl ? (
                <img
                  src={photoObjUrl}
                  alt="selected file"
                  style={{width: '100%', height: '100%', objectFit: 'contain'}}
                />
              ) : (
                <div>+ 選擇檔案</div>
              )}
            </label>
            <input
              id="photo_input"
              type="file"
              accept="image/*"
              onChange={(e) => {
                const f = e.target.files[0];
                if (f) {
                  setPhoto(f);
                  setPhotoObjUrl(URL.createObjectURL(f));
                } else {
                  setPhoto(null);
                  setPhotoObjUrl(null);
                }
              }}
            />
            <div
              style={{
                fontSize: 12,
                color: '#888',
                marginTop: 6,
                maxWidth: 250,
              }}>
              * 個人大頭照電子檔（格式：JPG 或 PNG；大小：1MB 以內；解析度：至少
              300dpi，請確保清晰度）
            </div>
          </div>
        </div>
        <div className="field">
          <label className="required">中文姓名</label>
          <Ant.Input {...extraInfoProps('name')} />
        </div>
        <div className="field">
          <label className="required">英文姓名(同護照/英文半形)</label>
          <Ant.Input
            {...extraInfoProps('name_eng')}
            placeholder="LEE FEI-FEI"
          />
        </div>
        <div className="field">
          <label className="required">性別</label>
          <Ant.Radio.Group
            onChange={(e) =>
              setExtraInfo({...extraInfo, gender: e.target.value})
            }
            value={extraInfo.gender}>
            <Ant.Radio value={true}>男</Ant.Radio>
            <Ant.Radio value={false}>女</Ant.Radio>
          </Ant.Radio.Group>
        </div>

        <div className="field">
          <label className="required">身分證字號</label>
          <Ant.Input {...extraInfoProps('id_card_number')} />
        </div>
        <div className="field">
          <label className="required">出生年月日</label>
          <Ant.Input type="date" {...extraInfoProps('birthday')} />
        </div>
        <div className="field">
          <label className="required">手機</label>
          <Ant.Input {...extraInfoProps('phone')} placeholder="0911222333" />
        </div>
        <div className="field">
          <label className="required">EMAIL(1)</label>
          <Ant.Input {...extraInfoProps('email')} className="long" />
        </div>
        <div className="field">
          <label>EMAIL(2)</label>
          <Ant.Input className="long" {...extraInfoProps('email_2')} />
        </div>
        <RjsfAddressField
          formData={extraInfo.contact_address}
          extra={{
            title: '聯絡地址',
            css: `& > label { &::after {content: '*'; color: red;} } flex-direction: row; margin-left: 0px; border-bottom: 1px solid #ddd; & > label {width: 160px;} @media only screen and (max-width: 768px) { flex-direction: column; }} `,
          }}
          onChange={(addr) => {
            setExtraInfo({
              ...extraInfo,
              contact_address: addr,
            });
          }}
        />
        <div className="field">
          <label className="required">專利師證書字號</label>
          <Ant.Input
            className="long"
            {...extraInfoProps('certificate_number')}
          />
        </div>

        <div className="field">
          <label className="required">國考年度(西元)</label>
          <Ant.Input
            {...extraInfoProps('national_examination_year')}
            placeholder={'2012'}
          />
        </div>

        <div className="field">
          <label style={{alignSelf: 'flex-start'}} className="required">
            專利師證書
          </label>
          <div className="file-picker">
            <label htmlFor="cert_input">
              {certObjUrl ? (
                <img
                  src={certObjUrl}
                  alt="selected file"
                  style={{width: '100%', height: '100%', objectFit: 'contain'}}
                />
              ) : (
                <div>+ 選擇檔案</div>
              )}
            </label>
            <input
              id="cert_input"
              type="file"
              onChange={(e) => {
                const f = e.target.files[0];
                if (f) {
                  setCert(f);
                  setCertObjUrl(URL.createObjectURL(f));
                } else {
                  setCert(null);
                  setCertObjUrl(null);
                }
              }}
            />
            <div style={{fontSize: 12, color: '#888', marginTop: 6}}>
              * 圖檔格式 (JPG, PNG等) 或是 pdf 皆可
            </div>
          </div>
        </div>

        <div className="field">
          <label>商標代理人登錄字號</label>
          <Ant.Input
            className="long"
            {...extraInfoProps('trademark_attorney')}
          />
        </div>

        <div className="field">
          <label className="required">中國專利代理師證書</label>
          <Ant.Radio.Group
            onChange={(e) =>
              setExtraInfo({...extraInfo, chpatent: e.target.value})
            }
            value={extraInfo.chpatent}>
            <Ant.Radio value={true}>取得</Ant.Radio>
            <Ant.Radio value={false}>未取得</Ant.Radio>
          </Ant.Radio.Group>
        </div>

        <div className="field">
          <label>取得中國專利代理師證書年份</label>
          <Ant.Input
            className="long"
            {...extraInfoProps('chpatent_detail')}
            placeholder="請填寫西元年份，如:2020。如未取得則無需填寫。"
          />
        </div>

        <div className="field">
          <label style={{alignSelf: 'flex-start'}} className="required">
            專長
          </label>
          <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              {ExpertiseList.map((it) => {
                return (
                  <Ant.Checkbox
                    key={it.field}
                    style={{marginLeft: 0, marginBottom: 5}}
                    checked={extraInfo.expertise[it.field]}
                    onChange={(e) => {
                      setExtraInfo({
                        ...extraInfo,
                        expertise: {
                          ...extraInfo.expertise,
                          [it.field]: e.target.checked,
                        },
                      });
                    }}>
                    {it.label}
                  </Ant.Checkbox>
                );
              })}
            </div>

            <div style={{display: 'flex', alignItems: 'center'}}>
              <Ant.Checkbox
                value={extraInfo.expertise.etc}
                onChange={(e) => {
                  setExtraInfo({
                    ...extraInfo,
                    expertise: {
                      ...extraInfo.expertise,
                      etc: e.target.checked,
                    },
                  });
                }}>
                其他
              </Ant.Checkbox>
              <Ant.Input
                style={{marginLeft: 20, zIndex: 1}}
                value={extraInfo.expertise.etc_text}
                onChange={(e) => {
                  setExtraInfo({
                    ...extraInfo,
                    expertise: {
                      ...extraInfo.expertise,
                      etc_text: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <h2 style={{border: 'none', paddingBottom: 6, marginBottom: 0}}>
        執行業務處所
      </h2>
      <p
        style={{
          color: '#888',
          paddingBottom: 10,
          borderBottom: `4px solid ${Theme.colors.main}`,
        }}>
        ＊依據專利師法第7條及第8條之規定，請詳細填寫執行業務處所相關資料。
      </p>
      <section>
        <div className="field">
          <label>中文名稱</label>
          <Ant.Input className="long" {...extraInfoProps('agency_name')} />
        </div>
        <div className="field">
          <label>英文名稱</label>
          <Ant.Input className="long" {...extraInfoProps('agency_name_eng')} />
        </div>
        <RjsfAddressField
          formData={extraInfo.agency_address}
          extra={{
            title: '地址',
            css: `flex-direction: row; margin-left: 0px; border-bottom: 1px solid #ddd; & > label {width: 160px;} @media only screen and (max-width: 768px) { flex-direction: column; }} `,
          }}
          onChange={(addr) => {
            setExtraInfo({
              ...extraInfo,
              agency_address: addr,
            });
          }}
        />
        <div className="field">
          <label>聯絡電話(國碼)</label>
          <Ant.Input
            {...extraInfoProps('agency_telephone_prefix')}
            placeholder={'+886'}
          />
        </div>
        <div className="field">
          <label>聯絡電話(含區碼)</label>
          <Ant.Input
            className="long"
            {...extraInfoProps('agency_telephone')}
            placeholder={'含區碼，例如:0227011990'}
          />
        </div>
        <div className="field">
          <label>聯絡電話(分機)</label>
          <Ant.Input
            className="long"
            {...extraInfoProps('agency_telephone_ext')}
          />
        </div>
        <div className="field">
          <label>傳真</label>
          <Ant.Input className="long" {...extraInfoProps('agency_fax')} />
        </div>
        <div className="field">
          <label>統一編號</label>
          <Ant.Input
            className="long"
            {...extraInfoProps('agency_business_id_number')}
          />
        </div>
      </section>

      <h2>相關資訊</h2>
      <section>
        <div className="field">
          <label className="required">
            是否同意將本人執行業務處所與聯絡電話等資料(中/英文)刊登於公會網頁
          </label>
          <Ant.Radio.Group {...extraInfoProps('public')}>
            <Ant.Radio value={true}>同意</Ant.Radio>
            <Ant.Radio value={false}>不同意</Ant.Radio>
          </Ant.Radio.Group>
          <p style={{color: '#888'}}>
            ＊提醒：若勾選不同意，公會網頁上仍會刊登會員姓名、公會會員編號及專利師證書字號，且公會仍需依規定設置紙本會員名簿刊登會員個人資料，提供公眾查閱。
          </p>
        </div>
        <div className="field">
          <label className="required">收據抬頭</label>
          <Ant.Input className="long" {...extraInfoProps('receipt_name')} />
        </div>
        <div className="field">
          <label>統編</label>
          <Ant.Input
            className="long"
            {...extraInfoProps('business_id_number')}
          />
        </div>
      </section>

      <h2>秘書聯絡資訊</h2>
      <section>
        <div className="field">
          <label>秘書姓名</label>
          <Ant.Input className="long" {...extraInfoProps('secretary_name')} />
        </div>
        <div className="field">
          <label>秘書電話</label>
          <Ant.Input className="long" {...extraInfoProps('secretary_phone')} />
        </div>
        <div className="field">
          <label>秘書 Email</label>
          <Ant.Input className="long" {...extraInfoProps('secretary_email')} />
        </div>
      </section>

      <Ant.Button
        style={{display: 'block', margin: '40px auto'}}
        disabled={disabled}
        type="primary"
        size="large"
        onClick={() => {
          submitRegistrationForm();
        }}>
        確認送出
      </Ant.Button>

      {validationErrorList.length > 0 && (
        <div className="err-fields">
          <div> ----- 請修正以下欄位後方可送出 ----- </div>
          <ul>
            {validationErrorList.map((it, idx) => {
              return <li key={idx}>{it}</li>;
            })}
          </ul>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  h1 {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 4px solid ${Theme.colors.main};
    margin-bottom: 25px;
  }

  h2 {
    margin-top: 40px;
    padding-bottom: 10px;
    margin-bottom: 5px;
    border-bottom: 4px solid ${Theme.colors.main};
  }

  & > section {
    padding-left: 20px;
  }

  .field {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px 0;
    border-bottom: 1px solid #ddd;

    & > label {
      width: 160px;
      &.required {
        &::after {
          content: '*';
          color: red;
          margin-top: -8px;
          margin-left: 2px;
        }
      }
    }

    input {
      width: 150px;

      &.long {
        width: 250px;
      }
    }

    & > .image-picker {
      label {
        width: 150px;
        height: 150px;
        border: 1px dashed #ccc;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      input[type='file'] {
        display: none;
      }
    }

    & > .file-picker {
      label {
        padding: 8px 16px;
        border: 1px dashed #ccc;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      input[type='file'] {
        display: none;
      }
    }
  }

  & > .err-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #888;
  }
`;

export default withPageEntry(MemberRegister);
