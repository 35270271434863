export function validatePendingRegisterForm(data) {
  const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const phonePattern = /^09[0-9]{8}$/;
  const idCardPattern = /^[A-Z][12]\d{8}$/;

  const {
    photo,
    cert,
    name = '',
    name_eng = '',
    email = '',
    email_2 = '',
    birthday = '',
    phone = '',
    gender = true,
    id_card_number = '',
    contact_address = {
      city: '',
      district: '',
      zip: '',
      detail: '',
    },
    certificate_number = '',
    national_examination_year = '',
    agency_name = '',
    agency_name_eng = '',
    agency_address = {
      city: '',
      district: '',
      zip: '',
      detail: '',
    },
    agency_address_eng = '',
    agency_telephone = '',
    agency_fax = '',
    agency_business_id_number = '',
    accept_agreement = false,
    expertise = {},
    receipt_name = '',
  } = data;

  const errFields = {};

  if (!photo) {
    errFields.photo = '照片為必要欄位';
  }
  if (!phone) {
    errFields.phone = '電話為必要欄位';
  }
  if (!cert) {
    errFields.cert = '必須上傳證書';
  }
  if (!name) {
    errFields.name = '中文名稱為必填';
  } else if (!name_eng) {
    errFields.name_eng = '英文名稱為必填';
  }
  if (!email) {
    errFields.email = 'EMAIL為必填';
  } else if (!emailPattern.test(email)) {
    errFields.email = 'EMAIL格式錯誤';
  }
  if (email_2 && !emailPattern.test(email_2)) {
    errFields.email = 'EMAIL(2)格式錯誤';
  }
  if (!birthday) {
    errFields.birthday = '生日為必填';
  }
  if (!phone) {
    errFields.phone = '手機為必填';
  } else if (!phonePattern.test(phone)) {
    errFields.phone = '手機格式錯誤';
  }
  if (!id_card_number) {
    errFields.id_card_number = '身分證字號為必填';
  } else if (!idCardPattern.test(id_card_number)) {
    errFields.id_card_number = '身分證字號格式錯誤';
  }
  if (!contact_address.city) {
    errFields.contact_address_city = '聯絡地址-縣市為必填';
  }
  if (contact_address.city !== '海外' && !contact_address.district) {
    errFields.contact_address_town = '聯絡地址-鄉鎮市區為必填';
  }
  if (contact_address.city !== '海外' && !contact_address.zip) {
    errFields.contact_address_zip_code = '聯絡地址-郵政編碼為必填';
  }
  if (!contact_address.detail) {
    errFields.contact_address_detail = '聯絡地址-詳細內容為必填';
  }
  if (!certificate_number) {
    errFields.certificate_number = '專利師證書字號為必填';
  }
  if (!national_examination_year) {
    errFields.national_examination_year = '國考年度為必填';
  }
  if (!accept_agreement) {
    errFields.accept_agreement = '尚未同意會員規章';
  }
  if (Object.keys(expertise).filter((it) => !!expertise[it]).length === 0) {
    errFields.expertise = '專長-至少要選一項';
  }

  if (!!expertise.etc && !expertise.etc_text) {
    errFields.expertise = '專長-其他-請填寫您的其他專長';
  }

  if (!receipt_name) {
    errFields.receipt_name = '收據抬頭-為必填';
  }

  return errFields;
}

export function validateProfileUpdateForm(data) {
  const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const phonePattern = /^09[0-9]{8}$/;
  const idCardPattern = /^[A-Z][12]\d{8}$/;

  const {
    phone = '',
    email = '',
    contact_address = {
      city: '',
      district: '',
      zip: '',
      detail: '',
    },
    expertise = {},
    receipt_name = '',
  } = data;

  const errFields = {};

  if (!email) {
    errFields.email = 'EMAIL為必填';
  } else if (!emailPattern.test(email)) {
    errFields.email = 'EMAIL格式錯誤';
  }
  if (!phone) {
    errFields.phone = '手機為必填';
  } else if (!phonePattern.test(phone)) {
    errFields.phone = '手機格式錯誤';
  }
  if (!contact_address.city) {
    errFields.contact_address_city = '聯絡地址-縣市為必填';
  }
  if (contact_address.city !== '海外' && !contact_address.district) {
    errFields.contact_address_town = '聯絡地址-鄉鎮市區為必填';
  }
  if (contact_address.city !== '海外' && !contact_address.zip) {
    errFields.contact_address_zip_code = '聯絡地址-郵政編碼為必填';
  }
  if (!contact_address.detail) {
    errFields.contact_address_detail = '聯絡地址-詳細內容為必填';
  }
  if (Object.keys(expertise).filter((it) => !!expertise[it]).length === 0) {
    errFields.expertise = '專長-至少要選一項';
  }
  if (!receipt_name) {
    errFields.receipt_name = '收據抬頭-為必填';
  }
  return errFields;
}
